/* SETUP FOR KEEP ASPECT RATIO VIEWPORT */
#root {
	position: absolute;
	width: 100%;
	height: 100%;
	font-size: 0.36rem;
}

#Main {
	position: fixed;
	margin: auto;
	top: 0 !important;
	right: 0;
	left: 0;
	bottom: 0;
	/* setup layout size */
	width: 13.34rem;
	height: 6.6rem;

	&:before {
		content: "";
		position: fixed;
		z-index: -2;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: top;
		background-image: var(--img-bg-main);
		// background-color: #000;
		/* Setup background for handle space on left and right or top and bottom. */
		// @include bgc;
		// background-size: 100%;
		// background-position: top;
		// background-image: var(--img-bg-main);
	}

	.content-container {
		width: 100%;
		height: 100%;
		position: relative;
		padding: 0.6rem 0.2rem;
		@include flexcc;
		justify-content: space-between;

		&.onspin {
			button {
				pointer-events: none !important;
			}
		}

		.content-wrapper {
			height: 75%;
			width: 100%;
			@include flexrc;
			justify-content: space-between;

			.main-content {
				flex-grow: 1;
				padding-left: 1%;
				height: 100%;
				min-width: 0;
			}
		}
	}
}

/* Modal layout setup */
.react-responsive-modal-root {
	display: flex;
	align-items: center;
	// height: 6.6rem;
	height: 100%;
	&.root-form {
		height: 100vh;
	}
}

.react-responsive-modal-overlay {
	overflow: hidden;
}

.react-responsive-modal-container {
	position: relative;
	display: flex;
	align-items: center;
	margin: 0 auto;
	font-size: 0.36rem;
}

.react-responsive-modal-modal {
	margin: 0 auto !important;
	max-width: none !important;
}
/* END SETUP FOR KEEP ASPECT RATIO VIEWPORT */
