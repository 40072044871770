.error-wrapper {
	@include flexcc;
	flex-grow: 1;
	min-height: 0;
	width: 100%;
	padding: 0 15%;

	> span {
		font-size: 0.3rem;
		color: #ffffff;
		text-align: center;
	}
}
