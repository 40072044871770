.puzzle-box {
	width: 100%;
	height: 100%;
	@include flexrc;
	justify-content: space-between;

	.puzzle-container {
		width: 90%;
		height: 100%;
		@include bgc;
		background-image: var(--img-bg-puzzle);
		@include flexrc;
		justify-content: space-between;

		&__wrapper {
			width: 72.4%;
			height: 100%;
			padding: 1%;
			display: grid;
			grid-auto-flow: row;
			grid-template-columns: repeat(4, 1fr);

			.puzzle-piece-base {
				position: relative;

				.puzzle-num {
					position: absolute;
					left: 3%;
					top: 8%;
					z-index: 1;
					font-size: 0.22rem;
					text-shadow: -0.01rem -0.01rem 0 #753c94, 0.01rem -0.01rem 0 #753c94, -0.01rem 0.01rem 0 #753c94,
						0.01rem 0.01rem 0 #753c94;
					color: #ffffff;
				}

				.puzzle-qty {
					position: absolute;
					right: 8%;
					top: 5%;
					z-index: 1;
					@include bgc;
					height: 0.22rem;
					width: 0.22rem;
					background-image: var(--img-ico-qty);
					@include flexcc;
					font-size: 0.1rem;
					color: #ffff47;
					font-weight: bold;
				}

				.piece {
					position: absolute;
					left: 0;
					top: 0;
					@include bgc;
					filter: grayscale(1);

					&.owned {
						filter: grayscale(0);
					}
				}

				&:nth-child(1) {
					.piece {
						width: 115%;
						height: 104%;
						left: -1%;
					}
				}
				&:nth-child(2) {
					.piece {
						left: -5%;
						height: 129%;
						width: 104%;
					}
				}
				&:nth-child(3) {
					.piece {
						left: -20%;
						width: 132%;
						height: 104%;
					}
				}
				&:nth-child(4) {
					.piece {
						width: 107%;
						height: 128%;
						left: -8%;
					}
				}
				&:nth-child(5) {
					.piece {
						width: 100%;
						height: 156%;
						top: -30%;
					}
				}
				&:nth-child(6) {
					.piece {
						width: 131%;
						height: 109%;
						left: -18%;
						top: -7%;
					}
				}
				&:nth-child(7) {
					.piece {
						width: 104%;
						height: 158%;
						left: -6%;
						top: -31%;
					}
				}
				&:nth-child(8) {
					.piece {
						width: 120%;
						height: 109%;
						left: -21%;
						top: -6%;
					}
				}
				&:nth-child(9) {
					.piece {
						width: 115%;
						height: 109%;
						left: -1%;
						top: -8%;
					}
				}
				&:nth-child(10) {
					.piece {
						width: 104%;
						height: 158%;
						left: -5%;
						top: -32%;
					}
				}
				&:nth-child(11) {
					.piece {
						width: 132%;
						height: 109%;
						left: -20%;
						top: -8%;
					}
				}
				&:nth-child(12) {
					.piece {
						width: 107%;
						height: 156%;
						left: -8%;
						top: -30%;
					}
				}
				&:nth-child(13) {
					.piece {
						width: 102%;
						height: 129%;
						left: -1%;
						top: -32%;
					}
				}
				&:nth-child(14) {
					.piece {
						width: 131%;
						height: 104%;
						left: -18%;
						top: -8%;
					}
				}
				&:nth-child(15) {
					.piece {
						width: 103%;
						height: 127%;
						left: -6%;
						top: -31%;
					}
				}
				&:nth-child(16) {
					.piece {
						width: 120%;
						height: 104%;
						left: -21%;
						top: -7%;
					}
				}
			}
		}

		.right-side {
			width: calc(100% - 72.4%);
			height: 100%;
			@include flexcc;
			justify-content: space-between;
			padding-right: 1.5%;
			padding: 1.5% 1.5% 1.5% 0;

			.redeem {
				@include flexcc;
				width: 100%;

				> span {
					color: #ffffff;
					text-transform: uppercase;
					font-size: 0.18rem;
					margin-bottom: 0.1rem;
					font-weight: bold;
				}

				.redeem-box {
					@include flexrc;
					justify-content: space-between;
					width: 100%;
					aspect-ratio: 6.4/1;
					background-color: #ffffff;
					border-radius: 0.03rem;
					padding: 0 1.5%;

					input {
						width: 66%;
						text-align: center;
						border: none;
						outline: none;
						font-size: 0.2rem;
						border-radius: 0;

						&::placeholder {
							text-transform: lowercase;
							color: #c0c0c0;
							font-size: 0.2rem;
						}
					}

					.btn-redeem {
						@include bgc;
						width: 33%;
						aspect-ratio: 2.5/1;
						background-size: 100% 100%;
						background-image: var(--img-btn-blank);
						@include flexrc;
						text-transform: uppercase;
						color: #8a5f34;
						font-size: 0.22rem;
						font-weight: bold;
					}
				}
			}

			.puzzle-spin {
				flex-grow: 1;
				width: 100%;
				padding: 10% 0 18%;
				@include flexcc;
				justify-content: space-between;

				&.disabled {
					pointer-events: none;
					filter: grayscale(1);
				}

				.puzzle-img {
					aspect-ratio: 0.99/1;
					width: 50%;
					@include bgc;
					background-image: var(--img-bg-envelope-reward);
					padding: 3% 3% 4%;
					position: relative;

					.new {
						position: absolute;
						top: 5%;
						right: -1%;
						@include bgc;
						height: 0.2rem;
						width: 0.2rem;
						background-image: var(--img-ico-notif-yellow);
					}

					&-shard {
						width: 100%;
						height: 100%;
						@include bgc;
						background-image: var(--img-ico-puzzlebox);
					}
				}

				> span {
					font-size: 0.22rem;
					text-transform: uppercase;
					text-align: center;
					color: #ffffff;
					padding: 0 10%;
					font-weight: bold;
				}

				.btn-open {
					@include bgc;
					background-image: var(--img-btn-blank);
					aspect-ratio: 3.41/1;
					width: 40%;
					@include flexrc;
					text-transform: uppercase;
					color: #8a5f34;
					font-size: 0.22rem;
					font-weight: bold;

					&.inactive {
						filter: grayscale(1);
						pointer-events: none;
					}
				}
			}
		}
	}

	.milestone {
		height: 100%;
		width: 10%;
		@include flexrc;
		justify-content: space-between;

		.bar-wrapper {
			align-self: flex-end;
			width: 35%;
			height: 90%;
			@include bgc;
			background-position: bottom center;
			background-size: 75%;
			background-image: var(--img-milestone-decor);

			.bar {
				height: 100%;
				width: 10%;
				margin: auto;
				background-image: linear-gradient(rgba(117, 60, 148, 1) 92%, rgba(255, 255, 255, 0) 8%);
				position: relative;
				display: grid;

				&-fill {
					width: 100%;
					bottom: 0;
					position: absolute;
					background-image: linear-gradient(
						0deg,
						rgba(255, 255, 255, 0) 0.28rem,
						rgba(255, 255, 255, 1) 0rem
					);
				}

				.checkpoint {
					width: 0.1rem;
					height: 0.1rem;
					border-radius: 50%;
					background-color: rgb(117, 60, 148);
					left: -30%;
					top: -5%;
					position: relative;

					&.passed {
						background-color: white;
					}
				}
			}
		}

		.item-wrappers {
			align-self: flex-start;
			width: 65%;
			height: 85%;
			@include flexcc;
			justify-content: space-between;

			.items {
				width: 100%;
				@include flexcc;

				&-img {
					width: 0.7rem;
					height: 0.7rem;
					margin-bottom: 10%;
					background-color: black;
					position: relative;
					@include bgc;

					&.claimable {
						&::before {
							@include bgc;
							mix-blend-mode: screen;
							background-image: var(--img-flash-item);
							position: absolute;
							width: 100%;
							height: 100%;
							content: "";
							top: 0;
							left: 0;
						}
					}

					> div {
						@include bgc;
						background-position: center top;
						background-size: auto 90%;
						width: 100%;
						height: 88%;

						.checkbox {
							width: 100%;
							height: 100%;
							position: relative;
							background: rgba(0, 0, 0, 0.5);

							&::after {
								content: "";
								@include bgc;
								background-image: var(--img-ico-check);
								background-size: 30%;
								position: absolute;
								width: 100%;
								height: 100%;
								left: 0;
								top: 0;
							}
						}
					}
				}

				span {
					color: #ffffff;
					font-size: 0.15rem;
					text-shadow: -0.01rem -0.01rem 0 #753c94, 0.01rem -0.01rem 0 #753c94, -0.01rem 0.01rem 0 #753c94,
						0.01rem 0.01rem 0 #753c94;
					white-space: nowrap;
				}
			}
		}
	}
}
