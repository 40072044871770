@import url("https://fonts.googleapis.com/css?family=Bebas+Neue&display=swap");
//Chinese font
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+TC&display=swap");
// VN RU font
@import url("https://fonts.googleapis.com/css?family=Yanone+Kaffeesatz&display=swap");
// Burmese (unicode) font
@import url("https://fonts.googleapis.com/css2?family=Padauk&display=swap");
// Number Font
@font-face {
	font-family: "Bebas Neue Number";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local("Bebas Neue Regular"), local("BebasNeue-Regular"),
		url(https://fonts.gstatic.com/l/font?kit=JTUSjIg69CK48gW7PXooxWtrzAbjydxFlRmQn1kIfkdt&skey=6bd981f07b300212&v=v1)
			format("woff2");
}
// Burmese (zawgyi)
@font-face {
	font-family: "Zawgyi-One";
	src: url("https://dl.dir.freefiremobile.com/common/web_event/common/fonts/Zawgyi-One_V3.1.otf")
			format("opentype"),
		url("https://dl.dir.freefiremobile.com/common/web_event/common/fonts/Zawgyi-One_V3.1.woff") format("woff"),
		url("https://dl.dir.freefiremobile.com/common/web_event/common/fonts/Zawgyi-One_V3.1.ttf")
			format("truetype");
}

// BMG default font
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Passion+One:wght@400;700;900&display=swap");

@font-face {
	font-family: "Gunar";
	font-weight: normal;
	src: url("https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/fonts/Gunar.ttf");
}
@font-face {
	font-family: "Gunar";
	font-weight: bold;
	src: url("https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/fonts/Gunar Bold.ttf");
}
@font-face {
	font-family: "Refrigerator Deluxe";
	font-weight: normal;
	src: url("https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/fonts/Refrigerator Deluxe.otf");
}
@font-face {
	font-family: "Refrigerator Deluxe";
	font-weight: bold;
	src: url("https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/fonts/RefrigeratorDeluxe-Bold.ttf");
}

@font-face {
	font-family: "PSL";
	font-style: normal;
	font-display: swap;
	src: url("https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/fonts/PSL174pro.ttf") format("woff2");
}

@font-face {
	font-family: "PSL";
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url("https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/fonts/PSL176pro.ttf") format("woff2");
}
