.form-wrapper {
	@include flexcc;
	justify-content: space-between;
	height: 100%;
	width: 100%;

	.title {
		text-align: center;
		color: #ffffff;
		font-size: 0.25rem;
		padding: 0 13%;
	}

	form {
		@include flexcc;
		justify-content: space-between;
		width: 100%;
		height: 100%;
		padding-top: 0.1rem;

		> div {
			@include flexcc;
			width: 100%;
			padding: 0 9%;

			> div {
				width: 100%;
				@include flexrc;
				justify-content: space-between;
				margin: 0.03rem 0;
				height: 0.4rem;

				label {
					width: 1.7rem;
					font-size: 0.22rem;
					color: #ffffff;
					text-transform: capitalize;
				}

				input {
					flex-grow: 1;
					height: 100%;
					border: none;
					font-size: 0.22rem;
					padding-left: 0.1rem;
					border-radius: 0;
					margin: 0;

					&:focus {
						outline: none;
					}
				}

				.country-code {
					width: 10%;
					background: #ffffff;
					font-size: 0.22rem;
					height: 100%;
					@include flexcc;
					margin-right: 0.08rem;
				}

				&.city {
					> div {
						@include flexrc;
						height: 100%;

						&:first-child {
							width: 60%;
						}
						&:last-child {
							width: 40%;

							label {
								text-align: center;
							}
						}
					}
				}
			}
		}

		.btn-confirm {
			padding: 0;
			border: 0;
			color: #000000;
			border-radius: 0;
		}
	}
}
