.confirmation-wrapper {
	@include flexcc;
	padding: 0 20%;
	justify-content: space-evenly;
	height: 70%;

	> span {
		text-align: center;
		color: #ffffff;
		font-size: 0.25rem;
	}

	.checkbox {
		@include flexrc;

		input {
			-webkit-appearance: none;
			appearance: none;
			margin: 0;
			width: 0.2rem;
			height: 0.2rem;
			background-color: #000000;
			border: 0.01rem solid yellow;
			margin-right: 0.12rem;
			border-radius: 0;
			vertical-align: middle;

			&:checked {
				@include bgc;
				background-image: var(--img-ico-checkmark);
			}
		}

		label {
			color: yellow;
			font-size: 0.22rem;
			font-style: italic;
			letter-spacing: 0.001rem;
			position: relative;
			top: 5%;
		}
	}
}

.buttons {
	width: 100%;
	@include flexrc;

	.btn {
		@include bgc;
		background-image: var(--img-btn-gold);
		aspect-ratio: 4.17/1;
		width: 19%;

		&-back {
			background-image: var(--img-btn-grey);
		}

		&:first-child {
			margin-right: 5%;
		}
	}
}
