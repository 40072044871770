.modal__overlay {
	background: url("https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/moonlightblessing/overlay.png")
		center/cover no-repeat !important;
}

.modal {
	width: 9.8rem;
	height: 5rem;
	padding: 0 !important;
	box-shadow: none !important;
	@include bgc;
	background-color: unset !important;
	background-image: var(--img-bg-popup) !important;
	background-size: contain !important;
	background-repeat: no-repeat !important;
	background-position: center !important;

	.btn-close {
		height: 0.4rem;
		width: 0.4rem;
		@include bgc;
		background-image: var(--img-btn-close);
		top: 2.3%;
		right: 0.8%;

		> svg {
			display: none;
		}
	}

	.category {
		position: absolute;
		top: 2.5%;
		left: 7.5%;
		text-transform: uppercase;
		font-weight: bold;
		color: #ffffff;
		font-size: 0.27rem;
	}

	.modal__container {
		width: 100%;
		height: 100%;
		padding: 8% 0 4%;
		@include flexcc;
		justify-content: space-between;

		.modal__title {
			@include flexcc;
			width: 100%;

			> div {
				text-transform: uppercase;
				font-weight: bold;
				color: yellow;
				font-size: 0.5rem;
				margin-bottom: 1%;
				letter-spacing: 0.01rem;
			}

			.subtitle {
				color: #ffffff;
				font-weight: normal;
				font-size: 0.24rem;
				width: 100%;
				padding: 0 15%;
				text-align: center;
			}
		}

		.modal__content {
			flex-grow: 1;
			min-height: 0;
			width: 100%;
			@include flexcc;
			justify-content: space-between;

			.btn {
				text-transform: uppercase;
				font-size: 0.25rem;
				font-weight: bold;

				&-confirm {
					@include bgc;
					background-image: var(--img-btn-gold);
					aspect-ratio: 4.17/1;
					width: 19%;
				}
			}
		}
	}
}

@import "success";
@import "confirmation";
@import "form";
@import "share";
@import "voucher";
@import "puzzle";
@import "error";
@import "history";
@import "rules";
@import "consent";
