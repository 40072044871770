.parcel-spin {
	width: 100%;
	height: 100%;
	@include flexrc;
	justify-content: space-between;

	.grandprize {
		width: 18%;
		height: 100%;
		@include bgc;
		background-position: bottom center;
		// background-position: top center;
		// background-size: auto 110%;
		background-image: var(--img-spin-main-reward);
	}

	.spin-wrapper {
		width: 48%;
		height: 100%;
		@include bgc;
		background-size: 95% auto;
		background-position: center 120%;
		background-image: var(--img-bg-overlay);
		@include flexcc;
		justify-content: space-between;

		.box-container {
			width: 100%;
			height: 80%;
			@include flexcc;

			.boxes {
				width: 100%;
				height: 80%;
				@include bgc;
				background-size: 85%;
				background-image: var(--img-box-static);
				@include flexcc;

				&.inactive {
					filter: grayscale(1);
				}

				&.shake {
					animation: headshake 1s ease-in-out infinite;
				}

				@keyframes headshake {
					0% {
						transform: translateX(0);
					}

					6.5% {
						transform: translateX(-6px);
					}

					18.5% {
						transform: translateX(5px);
					}

					31.5% {
						transform: translateX(-3px);
					}

					43.5% {
						transform: translateX(2px);
					}

					50% {
						transform: translateX(0);
					}
				}

				span {
					color: #ffffff;
					min-width: 57%;
					width: fit-content;
					padding: 3%;
					background: rgba(0, 0, 0, 0.8);
					font-size: 0.25rem;
					@include flexcc;
				}
			}

			.button-wrapper {
				width: 100%;
				height: 14.5%;
				text-align: center;
				position: relative;
				@include flexrc;

				.btn-spin {
					margin: auto;
					height: 100%;
					width: 42.5%;
					@include bgc;
					background-image: var(--img-bg-open-parcel-on);
					text-transform: uppercase;
					color: #ffffff;
					@include flexrc;
					font-size: 0.22rem;

					&.disabled {
						pointer-events: none;
						filter: grayscale(1);
					}

					.token-icon {
						@include bgc;
						background-image: var(--img-ico-token-2);
						width: 0.25rem;
						height: 0.25rem;
					}
				}

				.btn-history {
					height: 0.47rem;
					width: 0.47rem;
					position: absolute;
					right: 18%;
					margin: auto;
					@include bgc;
					background-image: var(--img-btn-history);
				}
			}
		}

		.reward-list {
			height: 20%;
			width: 100%;
			@include bgc;
			background-image: var(--img-bg-reward-list);
			padding: 0 1.6% 0 1.6%;
			position: relative;

			.reward-wrapper {
				padding: 0 1.5% 0 1.5%;
				width: 100%;
				height: 100%;
				margin: auto;
				@include flexrc;
				justify-content: flex-start;
				overflow-x: scroll;

				&::-webkit-scrollbar {
					display: none;
				}

				.item {
					height: 0.65rem;
					width: 0.65rem;
					flex-shrink: 0;
					@include bgc;

					> div {
						@include bgc;
						width: 100%;
						height: 88%;
						background-position: center top;
						background-size: auto 90%;
					}

					&:not(:last-child) {
						margin-right: 1.5%;
					}
				}
			}
		}
	}

	.luckywinner {
		width: 29%;
		height: 100%;
		@include bgc;
		background-image: var(--img-bg-lucky-winner);
		padding: 5.5% 2% 2%;

		.tabs {
			@include flexrc;
			font-size: 0.2rem;
			color: #753c94;
			text-transform: uppercase;
			font-weight: bold;
			width: 100%;

			.tab {
				width: 50%;
			}
		}

		.datalist {
			font-size: 0.2rem;
			width: 100%;
			margin-top: 3%;
			overflow-y: scroll;
			height: 90%;

			&::-webkit-scrollbar {
				display: none;
			}

			.data {
				width: 100%;
				@include flexrc;
				margin: 2% 0;

				> div {
					width: 50%;
					color: #8a5f34;
					padding-left: 5%;
				}
			}
		}
	}
}
