.voucher-wrapper {
	display: grid;
	color: #ffffff;
	width: 100%;
	justify-items: center;
	align-items: center;
	gap: 3%;
	position: relative;

	.item {
		width: 1.1rem;
		height: 1.1rem;
		@include bgc;
		background-image: var(--img-fx);

		> div {
			@include bgc;
			width: 100%;
			height: 100%;
			background-size: 95%;
		}
	}

	.item-name {
		font-size: 0.25rem;
		text-transform: uppercase;
	}

	.code {
		font-weight: bold;
		font-size: 0.35rem;
	}
	.wording {
		font-size: 0.25rem;
		padding: 0 20%;
		text-align: center;
	}
	.success-copy {
		color: #3fff3f;
		font-size: 0.2rem;
		position: absolute;
		bottom: -22%;
	}
}
