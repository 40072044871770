.item-wrapper {
	@include flexrc;
	flex-grow: 1;
	min-height: 0;

	> div {
		@include flexcc;
		width: 2.3rem;
		text-align: center;

		.item {
			width: 1.1rem;
			height: 1.1rem;
			@include bgc;
			margin-bottom: 10%;
			position: relative;

			&::before {
				position: absolute;
				content: "";
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				@include bgc;
				background-image: var(--img-box-effect);
			}

			> div {
				@include bgc;
				width: 86%;
				height: 86%;
				margin: 0 auto;
				background-size: 95%;
			}
		}

		> span {
			font-size: 0.25rem;
			color: #ffffff;
			text-transform: uppercase;
			height: 0.6rem;
		}
	}
}
