.header {
	flex-grow: 1;
	width: 100%;
	padding-bottom: 0.15rem;
	@include flexrc;
	justify-content: space-between;
	align-items: flex-start;
	position: relative;

	.left-wrapper {
		flex-grow: 1;
		font-weight: 400;

		.username {
			font-size: 0.22rem;
			width: 2rem;
			display: inline-block;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			vertical-align: middle;
			margin-right: 0.1rem;
		}

		.period {
			text-transform: uppercase;
			font-size: 0.17rem;
			vertical-align: middle;
		}
	}

	.title {
		@include flexcc;
		position: absolute;
		justify-content: space-around;
		padding-bottom: 0.25rem;
		width: 75%;
		left: 8% !important;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;

		&-main {
			@include bgc;
			background-image: var(--img-title);
			height: 0.6rem;
			width: 5.7rem;
		}

		&-sub {
			font-family: "Refrigerator Deluxe";
			font-size: 0.22rem;
		}
	}

	.right-wrapper {
		width: 25%;
		height: 100%;
		@include flexcc;
		justify-content: space-around;
		align-items: flex-end;
		z-index: 1;

		.buttons-wrapper {
			display: grid;
			grid-auto-flow: column;
			gap: 0.15rem;

			button {
				width: 0.5rem;
				height: 0.5rem;
				@include bgc;
				border: none;
				background-color: unset;

				&.btn-help {
					background-image: var(--img-btn-detail);
				}
				&.btn-bgm {
					&.on {
						background-image: var(--img-btn-sound-on);
					}
					&.off {
						background-image: var(--img-btn-sound-off);
					}
				}
			}

			> div {
				display: none;
			}
		}

		.tokens {
			@include flexrc;

			.token {
				width: 1rem;
				height: 0.32rem;
				@include bgc;
				font-size: 0.2rem;
				@include flexrc;
				justify-content: flex-start;
				padding-left: 20%;

				> span {
					position: relative;
					top: 5%;
				}

				&-a {
					background-image: var(--img-bg-token-gem);
				}
				&-b {
					background-image: var(--img-bg-token-ketupat);
					margin-left: 0.15rem;
				}
			}
		}
	}
}
