.history-wrapper {
	width: 100%;
	height: 100%;
	@include flexcc;
	padding: 0 15%;

	.history-header {
		width: 100%;
		height: 20%;
		text-transform: uppercase;
		@include flexrc;
		border: 0.01rem solid #ffffff;

		> span {
			font-size: 0.3rem;
			color: #ffffff;
			height: 100%;
			display: inline-block;
			@include flexrc;

			&:first-child {
				width: 40%;
				border-right: 0.02rem solid #ffffff;
				border-left: 0.03rem solid yellow;
			}
			&:last-child {
				width: 60%;
			}
		}
	}

	.history-body {
		width: 100%;
		height: 80%;
		overflow-y: auto;
		border: 0.01rem solid #ffffff;
		@include scrollbar;

		.list {
			height: 25%;
			@include flexrc;

			&:not(:last-child) {
				border-bottom: 0.01rem solid #ffffff;
			}

			> span {
				font-size: 0.25rem;
				text-transform: uppercase;
				color: #ffffff;
				height: 100%;
				display: inline-block;
				@include flexrc;

				&:first-child {
					width: 40%;
				}
				&:last-child {
					width: 60%;
				}
			}
		}
	}
}
