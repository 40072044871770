@import 'variable';
@import 'root';
@import 'font';
@import 'reset';
@import 'mixins';
@import 'functions';
@import 'lang';
/* setup for keep aspect ratio viewport*/
@import 'layout';

// pages & components
@import 'components/header';
@import 'components/navigation';
@import 'components/modal/modal';
@import 'pages/login';
@import 'pages/puzzle';
@import 'pages/spin';

// body {
// 	height: 100%;
// 	@include bgc;
// 	background-size: cover;
// 	background-image: var(--img-bg-main);
// }

.special-claim {
	position: fixed;
	@include bgc;
	background-image: var(--img-floating-glow);
	width: 1.3rem;
	height: 1.16rem;
	z-index: 2;
	top: 1.5rem;
	left: 24%;
}

* {
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

input {
	-webkit-touch-callout: auto !important;
	-webkit-user-select: auto !important;
	-khtml-user-select: auto !important;
	-moz-user-select: auto !important;
	-ms-user-select: auto !important;
	user-select: auto !important;
}

.tippy-content {
	font-size: 0.18rem;
}

/* FIXED FLICKER MODAL */
.react-responsive-modal-overlay,
.react-responsive-modal-container,
.react-responsive-modal-modal,
.react-responsive-modal-root {
	animation-fill-mode: forwards !important;
	top: 0 !important;
}

.common,
.na {
	background-image: var(--img-rarity-common);
}
.uncommon {
	background-image: var(--img-rarity-uncommon);
}
.rare {
	background-image: var(--img-rarity-rare);
}
.epic {
	background-image: var(--img-rarity-epic);
}
.legend {
	background-image: var(--img-rarity-legend);
}
.legendary {
	background-image: var(--img-rarity-legendary);
}

@keyframes scaleY {
	0% {
		opacity: 0;
		transform: scale3d(1, 0, 1);
	}
	100% {
		transform: scale3d(1, 1, 1);
		opacity: 1;
	}
}

@keyframes scaleYreverse {
	0% {
		opacity: 1;
		transform: scale3d(1, 1, 1);
	}
	100% {
		opacity: 0;
		transform: scale3d(1, 0, 1);
	}
}
