:root { 
--img-bg-envelope-reward : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/ae07a9f7-9b93-4089-8dde-f99205b58048.png'); 
--img-bg-item-long : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/8b6a405a-bd9e-4389-ba9a-0d16a27d271c.jpg'); 
--img-bg-item-square : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/1e6c78ec-6f7a-44a8-80cf-105d0f2ad7e0.jpg'); 
--img-bg-lucky-winner : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/de37e82b-0b54-4e9a-8889-00ac99229853.png'); 
--img-bg-main : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/ae1a9fac-ead8-4635-a0d8-c474a39c0b2c.jpg'); 
--img-bg-open-parcel-off : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/68b176bc-a48e-43d7-957e-b51f1ce42510.png'); 
--img-bg-open-parcel-on : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/8c212758-7be6-499c-a08b-34b1da929db8.png'); 
--img-bg-overlay : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/0c9973a5-ab6d-43f5-8cce-b54e5f8412c2.png'); 
--img-bg-popup : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/da76b6f4-4e0c-4ba8-8588-011a7e134647.png'); 
--img-bg-puzzle : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/26015b15-65e0-4e22-9366-1bf9ed8dc678.png'); 
--img-bg-reward : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/25516bb1-bf0a-4410-a969-4119ef6942a3.png'); 
--img-bg-reward-list : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/cddcc051-b7f4-4848-8b4b-bf69d5f49132.png'); 
--img-bg-sponsor : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/31130fce-24b2-4645-ae08-035ebd49f22e.png'); 
--img-bg-token-gem : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/17be22c3-3517-4112-a499-6ccf12f6e440.png'); 
--img-bg-token-ketupat : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/64cccffd-901e-4c02-b679-8f104fba584c.png'); 
--img-box-animate : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/af556475-8a15-479b-82c3-24ca208cf41b.gif'); 
--img-box-effect : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/3b267d03-b18c-4149-8cd2-af97024b1716.png'); 
--img-box-static : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/82bc9e58-93e2-428a-8e77-9d52e63fa77b.png'); 
--img-btn-blank : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/0fc626ef-e3b3-4754-9618-0756f9cd4a44.png'); 
--img-btn-claim-off : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/c704c23d-219d-4bfd-befb-e2d85c2bfd90.png'); 
--img-btn-claim-on : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/a672c082-7b7b-47bb-b14e-9710e482aafa.png'); 
--img-btn-close : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/5e72bf01-60c3-412e-8bc5-4e039f00c95b.png'); 
--img-btn-copy-link : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/111310ca-98cf-4989-827e-2064fdd2d470.png'); 
--img-btn-detail : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/ed6113f7-e97b-4d92-a632-f7a3e65411d8.png'); 
--img-btn-gold : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/594a790d-7569-4191-b5f7-c2b3586d61cd.jpg'); 
--img-btn-grey : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/7561d88d-a223-42f9-9aa7-7d4673e87f8a.jpg'); 
--img-btn-history : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/2caa6687-e32e-496c-82f4-e7e1a3695008.png'); 
--img-btn-share-event : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/d2e7820b-2757-481b-bc8b-de03ea6e3a6d.png'); 
--img-btn-sound-off : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/9ca92074-cf5a-46b7-8ccd-5573bf7d259a.png'); 
--img-btn-sound-on : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/72e58cdd-2d58-4432-b216-6323324ec9de.png'); 
--img-flash-item : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/e7d908d4-8a09-486b-b597-d7039bd61327.gif'); 
--img-floating- : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/e65642a9-3658-4f43-89ec-0bffa944c175.png'); 
--img-floating-glow : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/f0c4d848-23cd-4239-9778-983929ea39e3.png'); 
--img-fx : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/e5d6bcc7-dc77-4229-bffb-6fcf0d66a783.png'); 
--img-ico-arrow-off : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/821fafb2-2817-48a7-bd7d-e69aba890eb9.png'); 
--img-ico-arrow-on : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/85af5801-9a07-4b94-8888-999083bdf3a4.png'); 
--img-ico-check : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/4ca50742-0712-4790-ae69-5eb71c74159c.png'); 
--img-ico-checkmark : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/8d393755-c4cb-40de-9136-d2dbd53727b8.png'); 
--img-ico-gem : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/f0ba35c4-1cd8-4e53-bb20-df4c67b11afc.png'); 
--img-ico-notif-orange : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/5181c780-a64b-4ad9-a893-80beaf527b82.png'); 
--img-ico-notif-yellow : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/3da0297d-a177-47e3-9c71-2a03b4dc893c.png'); 
--img-ico-puzzlebox : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/eb2d59fc-3c5d-4ae1-bcfd-3d3ee1f32aa9.png'); 
--img-ico-qty : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/b34dc295-9180-44a4-8c68-143d86c39a14.png'); 
--img-ico-tab-off : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/3a0654a1-85d2-4596-83ff-369802565ae3.png'); 
--img-ico-tab-on : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/8760921d-0353-4f85-9438-74923aee68bf.png'); 
--img-ico-token-2 : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/82853480-3220-40cf-aea2-13fe7535f001.png'); 
--img-main-reward : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/6460371b-18f6-423f-9ed4-14f24bf5afac.png'); 
--img-menu-off : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/cf2a1536-ff1c-4b5e-a3be-c43a0f22b6b5.png'); 
--img-menu-on : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/9c68a000-2be0-442a-a27c-017ea1e4a361.png'); 
--img-milestone-decor : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/f4134ffb-30b0-4ba0-aa6b-2aeda5cb18a5.png'); 
--img-share-fb : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/2d97c281-039b-463d-b951-1a8011cea523.png'); 
--img-share-line : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/3bef9b6a-a39d-42c5-88d3-b6ce690eaef2.png'); 
--img-share-wa : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/5dc85ea8-98f6-4d21-acd1-ef326957b2f7.png'); 
--img-spin-main-reward : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/62ac38c8-621a-4236-a741-fbad21221966.png'); 
--img-title : url('https://cdngarenanow-a.akamaihd.net/gstaticid/drcts/bebde4b0-91ea-49f9-8f97-b9ca4d20723f.png'); 
}