.navigation {
	height: 100%;
	width: 15.65%;
	@include flexcc;
	justify-content: space-between;

	.navigation-wrapper {
		@include flexcc;
		width: 100%;

		.btn {
			box-sizing: content-box;
			padding: 2% 0;
			@include bgc;
			width: 100%;
			height: 0.475rem;
			background-image: var(--img-menu-off);
			@include flexrc;
			text-decoration: none;
			text-transform: uppercase;
			font-size: 0.25rem;
			font-weight: 700;
			color: rgb(138, 95, 52);
			position: relative;

			&.active {
				color: #ffffff;
				background-image: var(--img-menu-on);
			}

			.new {
				position: absolute;
				top: -8%;
				right: 0;
				@include bgc;
				width: 0.2rem;
				height: 0.2rem;
				background-image: var(--img-ico-notif-yellow);
			}
		}
	}

	.btn-share {
		margin-top: 10%;
		@include bgc;
		background-image: var(--img-btn-share-event);
		width: 100%;
		height: 0.475rem;
		position: relative;

		.new {
			position: absolute;
			top: -8%;
			right: 0;
			@include bgc;
			width: 0.2rem;
			height: 0.2rem;
			background-image: var(--img-ico-notif-yellow);
		}
	}

	.sponsor-sliders {
		width: 100%;
		@include bgc;
		background-image: var(--img-bg-sponsor);
		padding: 4%;

		.slick-slider,
		.slick-list,
		.slick-track,
		.slick-slide,
		.slick-slide > div {
			height: 100%;
		}

		.slick-slide {
			padding: 3%;
		}

		img {
			pointer-events: none;
			vertical-align: middle;
			height: 100%;
		}

		.slick-dots {
			bottom: -13%;

			li {
				width: 0.04rem;
				height: 0.04rem;
				height: unset;
				margin: 0 2%;

				button {
					width: 100%;
					height: 100%;
					background-color: #ffffff;
					border-radius: 50%;
					padding: 0;

					&::before {
						content: unset;
					}
				}

				&.slick-active {
					button {
						background-color: #381e3f;
					}
				}
			}
		}
	}
}
