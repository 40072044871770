.consent-wrapper {
	@include flexcc;
	justify-content: space-between;
	flex-grow: 1;
	min-height: 0;
	width: 100%;
	padding: 0 15%;
	color: #ffffff;

	.consent-title {
		@include flexcc;

		span {
			text-align: center;
			line-height: 1.3;

			&:first-child {
				font-size: 0.3rem;
			}
			&:nth-child(2) {
				font-size: 0.22rem;
			}
			&:last-child {
				font-size: 0.25rem;
			}
		}
	}

	.text-wrapper {
		width: 100%;
		height: 65%;
		margin-bottom: 2%;
		background: rgba(255, 255, 255, 0.1);
		overflow-y: scroll;
		@include scrollbar;
		padding: 0.2rem 0.3rem;

		> div {
			width: 100%;
			height: 100%;
			font-size: 0.2rem;
			line-height: 1.3;
		}
	}
}
