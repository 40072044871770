.share-wrapper {
	width: 100%;
	height: 70%;
	@include flexcc;
	justify-content: space-around;
	position: relative;

	> span {
		color: #ffffff;
		font-size: 0.25rem;
		padding: 0 15%;
		text-align: center;
	}

	.copymsg {
		position: absolute;
		bottom: -6%;
		color: yellow;
	}

	.sharelist {
		@include flexrc;
		// width: 30%;
		justify-content: center;

		> button,
		> div {
			margin: 0 0.2rem;
		}

		.ico {
			height: 0.5rem;
			width: 0.5rem;
			@include bgc;

			&-fb {
				background-image: var(--img-share-fb);
			}
			&-line {
				background-image: var(--img-share-line);
			}
			&-wa {
				background-image: var(--img-share-wa);
			}
			&-copy {
				background-image: var(--img-btn-copy-link);
			}
		}
	}
}
