.daily-login {
	width: 100%;
	height: 100%;
	@include bgc;
	background-image: var(--img-bg-reward);
	@include flexrc;
	justify-content: space-between;
	padding-right: 1%;

	.main-reward {
		height: 100%;
		width: 19%;
		position: relative;
		top: -2.5%;
		@include bgc;
		background-size: 96%;
		background-image: var(--img-main-reward);
		padding-top: 1%;
		@include flexcc;
		justify-content: space-between;

		.grandprize-img {
			width: 100%;
			height: 80%;
			@include bgc;
		}

		.shard-owned {
			@include flexrc;
			width: 100%;

			span {
				font-size: 0.13rem;
				color: #8a5f34;
				font-family: 'Gunar' !important;
				white-space: nowrap;

				> span {
					font-weight: bold;
				}
			}

			img {
				height: 0.25rem;
			}
		}

		.btn-claim {
			@include bgc;
			background-image: var(--img-btn-claim-on);
			width: 65%;
			height: 9.1%;
			@include flexrc;
			text-transform: uppercase;
			color: #ffffff;
			font-size: 0.25rem;
			font-weight: bold;

			&.disabled {
				filter: grayscale(1);
				pointer-events: none;
			}
		}
	}

	.login-reward__wrapper {
		// position: relative;
		// flex-grow: 1;
		width: 79.5%;
		height: 100%;
		margin-left: 1.5%;
		padding: 1.5% 0%;
		@include flexcc;
		overflow-x: auto;
		overflow-y: hidden;
		flex-wrap: wrap;

		&::-webkit-scrollbar {
			display: none;
		}

		.week {
			height: 100%;
			width: 7.56rem;
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			grid-auto-flow: row;
			align-content: space-between;
			gap: 1.5%;
			margin-right: 1.5%;

			.reward-box {
				@include flexcc;
				@include bgc;
				background-size: 100% 100%;
				background-image: var(--img-bg-item-square);
				@include flexcc;
				width: 1.8rem;

				.box-wrapper {
					width: 1.8rem;
					height: 1.8rem;
					@include flexcc;
					justify-content: space-between;
					padding: 8.5% 0 4% 0;

					.item-img {
						width: 1.17rem;
						height: 1.17rem;
						position: relative;
						@include bgc;

						&.box-active::before {
							position: absolute;
							content: '';
							width: 100%;
							height: 100%;
							top: 0;
							left: 0;
							@include bgc;
							background-image: var(--img-flash-item);
							mix-blend-mode: screen;
						}

						&.box-claimed::before {
							position: absolute;
							content: '';
							width: 100%;
							height: 100%;
							top: 0;
							left: 0;
							background: rgba(0, 0, 0, 0.5);
						}

						> div {
							@include bgc;
							width: 100%;
							height: 88%;
							background-size: auto 90%;
							background-position: top center;

							&.checkbox {
								position: absolute;
								left: 0;
								top: 0;
								width: 100%;
								height: 100%;
								@include bgc;
								background-image: var(--img-ico-check);
								background-size: 25%;
								z-index: 1;
							}
						}
					}

					.item-name {
						text-transform: capitalize;
						color: #8a5f34;
						font-family: 'Gunar' !important;
						font-weight: bold;
						font-size: 0.15rem;
						position: relative;
						top: -5%;
					}
				}

				&:not(:last-child) {
					height: 1.8rem;
				}

				&--7 {
					grid-column: 4;
					background-image: var(--img-bg-item-long);
					grid-row: 1/3;

					.box-wrapper {
						position: relative;
						top: 4%;

						.item-name {
							top: 0;
						}
					}
				}
			}
		}
	}
}
