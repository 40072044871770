.puzzle-wrapper {
	@include flexcc;
	justify-content: center;
	flex-grow: 1;
	min-height: 0;
	width: 100%;

	.item {
		height: 1.1rem;
		width: 1.1rem;
		@include bgc;
		position: relative;

		&::before {
			position: absolute;
			content: "";
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			@include bgc;
			background-image: var(--img-box-effect);
		}

		> div {
			@include bgc;
			width: 100%;
			height: 100%;
			background-size: 85%;
		}
	}

	> span {
		color: #ffffff;

		&.puzzle-num {
			font-size: 0.25rem;
			font-weight: bold;
			text-transform: uppercase;
			margin: 1% 0;
		}

		&.puzzle-code {
			font-size: 0.35rem;
			font-weight: bold;
			margin: 0.5% 0;
		}

		&.puzzle-desc {
			font-size: 0.25rem;
			padding: 0 10%;
			text-align: center;
		}

		&.success-copy {
			color: #3fff3f;
			font-size: 0.2rem;
			position: absolute;
			bottom: 18%;
		}
	}
}
