.rules-wrapper {
	width: 100%;
	height: 100%;
	@include flexcc;
	justify-content: space-between;

	.rules-tab {
		@include flexrc;
		min-height: 0;
		height: fit-content;

		> div {
			@include bgc;
			min-width: fit-content;
			width: 2.1rem;
			background-image: var(--img-ico-tab-on);
			font-size: 0.22rem;
			@include flexrc;
			position: relative;

			.new {
				position: absolute;
				top: -40%;
				right: 2%;
				@include bgc;
				height: 0.23rem;
				width: 0.23rem;
				background-image: var(--img-ico-notif-yellow);
			}

			> span {
				padding: 0.03rem 0;
			}

			&.inactive {
				background-image: var(--img-ico-tab-off);
			}

			&.tab--howto {
				right: -2%;
			}
			&.tab--rules {
				left: -2%;
			}
		}
	}

	.content-wrapper {
		height: 85%;
		width: 100%;
		padding: 0 10%;

		.rules-content {
			width: 100%;
			height: 100%;
			overflow-y: auto;
			overflow-x: hidden;
			color: #ffffff;
			font-size: 0.22rem;
			line-height: 0.25rem;
			@include scrollbar;

			ol {
				list-style: decimal;
			}

			ul {
				list-style: disc;
			}

			ol,
			ul {
				padding-inline-start: 4%;
			}
		}

		.howto-content {
			width: 100%;
			height: 95%;

			.slick-slider,
			.slick-list,
			.slick-track,
			.slick-slide,
			.slick-slide > div {
				height: 100%;
				text-align: center;
			}

			img {
				vertical-align: middle;
				height: 100%;
				width: unset !important;
			}

			.slick-arrow {
				@include bgc;
				width: 4%;
				height: 100%;
				z-index: 1;
				background-image: var(--img-ico-arrow-on);

				&.slick-disabled {
					background-image: var(--img-ico-arrow-off);
				}

				&::before {
					content: unset;
				}

				&.slick-prev {
					top: 0;
					transform: scaleX(-1);
				}
			}

			.slick-dots {
				bottom: -8%;

				li {
					width: 0.1rem;
					height: 0.1rem;
					height: unset;
					margin: 0 2%;

					button {
						width: 100%;
						height: 100%;
						background-color: #ffffff;
						border-radius: 50%;
						padding: 0;

						&::before {
							content: unset;
						}
					}

					&.slick-active {
						button {
							background-color: yellow;
						}
					}
				}
			}
		}
	}
}
